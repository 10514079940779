import unorm from 'unorm'
import {remove} from "lodash";

const dev = process.env.NODE_ENV === 'development'
const folder = dev
  ? 'http://localhost:3000/images'
  : 'http://cms.diferpan.com.br:3000/images'

export const getImage = (name) => `${folder}/${name}`

export const convertToBase64 = (selectedFile, cb) => {
  if (selectedFile.length > 0) {
    let fileToLoad = selectedFile[0]
    let fileReader = new FileReader()
    let base64
    fileReader.onload = function(fileLoadedEvent) {
      base64 = fileLoadedEvent.target.result
      cb(base64)
    }
    fileReader.readAsDataURL(fileToLoad)
  }
}

export function isCpf(y = '') {
  if (!y) return false
  var x = y.replace(/\D/g, '')
  var soma = 0
  var resto
  var i = 0
  if (x === '00000000000') {
    return false
  }
  for (i = 1; i <= 9; i += 1) {
    soma = soma + parseInt(x.substring(i - 1, i), 10) * (11 - i)
  }
  resto = (soma * 10) % 11
  if (resto === 10 || resto === 11) {
    resto = 0
  }
  if (resto !== parseInt(x.substring(9, 10), 10)) {
    return false
  }
  soma = 0
  for (i = 1; i <= 10; i++) {
    soma = soma + parseInt(x.substring(i - 1, i), 10) * (12 - i)
  }
  resto = (soma * 10) % 11
  if (resto === 10 || resto === 11) {
    resto = 0
  }
  if (resto !== parseInt(x.substring(10, 11), 10)) {
    return false
  }
  return true
}

export function normalizeString(value) {
  return unorm
    .nfd(value.toString())
    .trim()
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s\s+/g, ' ')
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export function toMonetary(value) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value)
}

export function toDecimal(value) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
  }).format(value)
}

export function toPercent(value) {
  let percentVal = value / 100
  return new Intl.NumberFormat('pt-BR', { style: 'percent' }).format(percentVal)
}

export function toDateTime(date) {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  }
  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(date))
}

export function toDate(date) {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  }
  return new Intl.DateTimeFormat('en-US', options).toFormat
}

export function loadDescs(moduloName) {
  const { modulos } = JSON.parse(localStorage.getItem('app'))
  const { descPlural, descSingular } = modulos.find(
    (m) => m.nome === moduloName
  )
  return {
    descPlural,
    descSingular
  }
}

export function intToYesOrNo(intValue = -1) {
  if (intValue === 1) {
    return 'Sim'
  }

  if (intValue === 0) {
    return 'Não'
  }

  return 'N/A'
}

export function makeHash(size = 5) {
  let text = "";
  let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < size; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text
}

export function baseName(url) {
  return url.split('/').pop();
}

export function toggleValue(values, value) {
    const aux = values.slice(0)
    const removed = remove(aux, (n) => n === value)
    if (!removed.length) aux.push(value)
    return aux
}

export function toggleObjByIndex(values, value, indexBy) {
    const aux = values.slice(0)
    const removed = remove(aux, (n) => n[indexBy] === value[indexBy])
    if (!removed.length) aux.push(value)
    return aux
}

export function arrayFilter(array, termo, fieldsList) {

  if (termo === '') {
    return array;
  }

  return array.filter((arrayItem) => {
    let stringComparator = '';

    fieldsList.map((field) => {
      stringComparator += ' ' + normalizeString(`${arrayItem[field]}`.toLowerCase());
    });

    const stringsToCompare = normalizeString(`${termo}`.toLowerCase()).split(' ');

    for (let i = 0; i < stringsToCompare.length ; i++) {
      if (stringComparator.indexOf(stringsToCompare[i]) < 0) {
        return false
      }
    }

    return true;
  });
}