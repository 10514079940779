import { render, staticRenderFns } from "./TablePaginated.vue?vue&type=template&id=755f890c&scoped=true&"
import script from "./TablePaginated.vue?vue&type=script&lang=js&"
export * from "./TablePaginated.vue?vue&type=script&lang=js&"
import style0 from "./TablePaginated.vue?vue&type=style&index=0&id=755f890c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "755f890c",
  null
  
)

export default component.exports