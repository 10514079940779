<template>
    <div>
        <div class="table-responsive">
            <datatable
                :columns="columnsFormated"
                :data="itens"
                class="table table-hover"
            >
                <template slot="no-results">
                  <div class="text-center">
                    Nenhum resultado encontrado
                  </div>
                </template>
                <template slot-scope="{ row }">
                    <tr>
                        <td v-for="column in columnsFormated">
                            <template v-if="isKeyValue(row, column)">
                              <KeyValueTable :dataObject="row[column.field]" />
                            </template>
                            <template v-else-if="column.representedAs">
                                {{ column.representedAs(row) }}
                            </template>
                            <template v-else-if="column.field">
                                {{ row[column.field] }}
                            </template>
                            <template v-else>
                                <div class="text-right actions">
                                    <slot
                                        name="action-buttons"
                                        :model="row"
                                    />
                                    <CButton
                                        v-if="onUpdate != null"
                                        size="sm"
                                        color="primary"
                                        @click="onUpdate(row)"
                                    >
                                        <fa-icon icon="pencil-alt" />
                                    </CButton>
                                    <CButton
                                        v-if="onDelete != null"
                                        size="sm"
                                        @click="onDelete(row)"
                                        color="danger"
                                    >
                                        <fa-icon icon="trash-alt" />
                                    </CButton>
                                    <CButton
                                        v-if="onShow != null"
                                        size="sm"
                                        @click="onShow(row)"
                                        color="info"
                                    >
                                        <fa-icon icon="info-circle" />
                                    </CButton>
                                </div>
                            </template>
                        </td>
                    </tr>
                </template>
            </datatable>
        </div>
        <pagination
            :data="paginator"
            :limit="2"
            @pagination-change-page="onPageChange"
        />
    </div>
</template>

<script>
  import clone from 'just-clone';
  import KeyValueTable from './KeyValueTable';
  import _isObject from "lodash/isObject";

  export default {
    name: "TablePaginated",
    props: [
      'columns',
      'itens',
      'paginator',
      'onDelete',
      'onUpdate',
      'onShow',
      'onPageChange'
    ],
    components: {
      KeyValueTable
    },
    computed: {
      columnsFormated() {
        let rawColumns = clone(this.columns);

        if (this.onDelete || this.onUpdate || this.onShow) {
          rawColumns.push({ label: '#', sortable: false, })
        }

        return rawColumns;
      }
    },
    methods: {
      isKeyValue(row, column) {
        return column.isKeyValue && _isObject(row[column.field])
      }
    }
  }
</script>

<style scoped>
  /*.action-buttons * {*/
  /*  margin-left: 4px;*/
  /*}*/
</style>